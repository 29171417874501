<template>
  <div>
    <router-view />
    <iframe
      id="ulaIframe"
      title="ulaIframe"
      name="ulaIframe"
      sandbox="allow-scripts allow-same-origin allow-storage-access-by-user-activation"
      :src="ulaURL"
      :style="'right: 0; position: absolute; width: 360px; display: none'"
    ></iframe>
  </div>
</template>
<script lang="ts" setup>
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { UlaEvent } from '@/types/ula';
import { useAuthentication } from '@/composables/auth/useAuthentication';
import { useConfig } from '@/composables/useConfig';
import { onMounted } from 'vue';
import { useWebSocket } from '@/composables/useWebSocket';
import { useLogger } from '@/composables/useLogger';
const { isLogged, initLoggedUser, logout, refreshLogin } = useAuthentication();
const { createSocket, getSocket } = useWebSocket();
const { setConfig, getConfig } = useConfig();
const { logger } = useLogger();
const socket = getSocket();
const ulaURL = getConfig().ULA_URL;

if (import.meta.env.MODE != 'test') {
  const registratorUpdateInterval = 10 * 1000;
  useRegisterSW({
    onRegisteredSW(swUrl, registration) {
      if (registration)
        setInterval(async () => {
          if (!(!registration.installing && navigator)) return;

          if ('connection' in navigator && !navigator.onLine) return;

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          });

          if (resp?.status === 200) {
            await registration.update();
          }
        }, registratorUpdateInterval);
    },
  });
}

onMounted(() => {
  window.onmessage = async (e: MessageEvent<UlaEvent>): Promise<void> => {
    if (e.origin === getConfig().ULA_URL) {
      logger.debug(`📨 message from ULA iframe: ${e.data.name}`, e.data);
    } else {
      return;
    }

    switch (e.data.name) {
      case 'ula-user-identity': {
        if (!getConfig().TENANT) {
          setConfig({
            ...getConfig(),
            TENANT: e.data.user.tenant,
          });
        }

        if (!socket) {
          createSocket();
        }

        if (!isLogged.value) {
          await initLoggedUser(e.data.user);
        } else {
          await refreshLogin(e.data.user);
        }
        break;
      }
      case 'ula-logout': {
        logger.info("'ula-logout' message from ULA iframe, logging out...");
        await logout(false);
        break;
      }
      case 'ula-id-token':
        break; // handled as promise based communication in useUla composable
      default: {
        logger.debug('Unknown message from ULA iframe: ', e);
      }
    }
  };
});
</script>
