import { useAuthentication } from '@/composables/auth/useAuthentication';
import CollaboratorProfilesPage from '@/pages/sales/CollaboratorProfilesPage.vue';
import { SubmenuItem } from '@/types/menu';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { useConfig } from './composables/useConfig';

const LoginLayout = () => import('./layouts/LoginLayout.vue');
const AppLayout = () => import('./layouts/AppLayout.vue');
const HomePage = () => import('./pages/HomePage.vue');
const SettingsPage = () => import('./pages/SettingsPage.vue');
const SpreadsheetPage = () => import('./pages/SpreadsheetPage.vue');
const ProjectListPage = () => import('./pages/projects/ProjectListPage.vue');
const InvoiceListPage = () => import('./pages/finance/InvoiceListPage.vue');
const OrganizationListPage = () => import('./pages/sales/OrganizationListPage.vue');
const BankTransactionListPageV2 = () => import('./pages/finance/BankTransactionListPageV2.vue');
const InvoiceReminderListPage = () => import('./pages/analysis/InvoiceReminderListPage.vue');
const GoogleDocLayout = () => import('./layouts/GoogleDocLayout.vue');
const WorkLogAnalysisPage = () => import('./pages/analysis/WorkLogAnalysisPage.vue');
const CostCategoryAnalysisPage = () => import('./pages/analysis/CostCategoryAnalysisPage.vue');
const WorkLogIssueHelp = () => import('./components/WorkLogIssueComponents/WorkLogIssueHelp.vue');
const CollaboratorInternalListPage = () => import('./pages/hr/CollaboratorInternalListPage.vue');
const CollaboratorExternalListPage = () => import('./pages/hr/CollaboratorExternalListPage.vue');
const InvoiceAnalysisPage = () => import('./pages/analysis/InvoiceAnalysisPage.vue');
const InvoiceAnalysisHelp = () => import('./components/InvoiceAnalysisComponents/InvoiceAnalysisHelp.vue');
const PersonalSettingsPage = () => import('./pages/PersonalSettingsPage.vue');
const CollaboratorWorkPlanPageV2 = () => import('./pages/planning/CollaboratorWorkPlanPageV2.vue');
const CollaboratorProjectPlanPageV2 = () => import('./pages/planning/CollaboratorProjectPlanPageV2.vue');
const ProjectPlanHelp = () => import('./components/ProjectPlanComponents/ProjectPlanHelp.vue');
const CollaboratorCostListPage = () => import('./pages/finance/CollaboratorCostListPage.vue');
const CostsPlanningPage = () => import('./pages/finance/CostsPlanningPage.vue');
const CompanyCalculationPage = () => import('./pages/finance/CompanyCalculationPage.vue');
const CollaboratorSkillsPage = () => import('./pages/hr/CollaboratorSkillsPage.vue');
const AssetListPage = () => import('./pages/finance/AssetListPage.vue');
const ProjectCalculationPage = () => import('./pages/projects/ProjectCalculationPage.vue');
const ProjectOverviewPage = () => import('./pages/projects/ProjectOverviewPage.vue');
const OrderListPage = () => import('./pages/sales/OrderListPage.vue');
const CompanyWorkPerformancePage = () => import('./pages/analysis/CompanyWorkPerformancePage.vue');
const AvailableCapacityReportPageV3 = () => import('./pages/sales/AvailableCapacityReportPageV3.vue');
const HomePageHelp = () => import('./components/HomePageComponents/HomePageHelp.vue');
const ProjectOverallCalculationPage = () => import('./pages/finance/ProjectOverallCalcualtionPage.vue');
const CompanyCalculationAnalysisPage = () => import('@/pages/analysis/CompanyCalculationAnalysisPage.vue');
const OrderRoadmapPage = () => import('@/pages/sales/OrderRoadmapPage.vue');
const SalesProjectsStatisticsPage = () => import('./pages/sales/SalesProjectsStatisticsPage.vue');
const wrapperComponent = { template: '<router-view></router-view>' };

export const ORDERS_ROUTE = '/app/sales/orders';

export const routes: Array<RouteRecordRaw> = [
  { path: '/', component: LoginLayout },
  {
    path: '/google-doc',
    component: GoogleDocLayout,
    children: [
      {
        path: 'document/:id',
        component: SpreadsheetPage,
      },
    ],
  },
  {
    path: '/app',
    component: AppLayout,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: HomePage,
        meta: {
          helpComponent: HomePageHelp,
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: SettingsPage,
        meta: {
          permission: {
            subject: 'AGW_APP',
            action: 'LOGIN',
          },
        },
      },
      {
        path: 'personal-settings',
        name: 'Personal Settings',
        component: PersonalSettingsPage,
      },
      {
        path: 'sales',
        name: 'Sales',
        component: wrapperComponent,
        children: [
          {
            path: 'organizations',
            name: 'Organizations',
            component: OrganizationListPage,
            meta: {
              permission: {
                subject: 'ERP_ORGANIZATIONS',
                action: 'READ',
              },
            },
          },
          {
            path: 'orders',
            name: 'Orders',
            component: OrderListPage,
            meta: {
              permission: {
                subject: 'ERP_ORDERS',
                action: 'READ',
              },
              key: 'orders',
            },
          },
          {
            path: 'employee-capacity-report-v3',
            name: 'Available capacity report',
            component: AvailableCapacityReportPageV3,
            meta: {
              permission: {
                subject: 'ERP_COLLABORATOR_CAPACITY_REPORT',
                action: 'READ',
              },
            },
          },
          {
            path: 'orders-roadmap',
            name: 'Orders roadmap',
            component: OrderRoadmapPage,
            meta: {
              permission: {
                subject: 'ERP_ORDERS',
                action: 'READ',
              },
            },
          },
          {
            path: 'sales-projects-statistics/month/:month',
            name: 'Sales projects statistics',
            component: SalesProjectsStatisticsPage,
            meta: {
              permission: {
                subject: 'ERP_SALES_PROJECTS_STATISTICS',
                action: 'READ',
              },
            },
          },
          {
            path: 'collaborator-profiles',
            name: 'Collaborator Profiles',
            component: CollaboratorProfilesPage,
            meta: {
              permission: {
                subject: 'ERP_COLLABORATOR_PROFILES',
                action: ['READ_OWN', 'READ_ALL'],
              },
            },
          },
        ],
      },
      {
        path: 'projects',
        name: 'Projects',
        component: wrapperComponent,
        children: [
          {
            path: 'project-list',
            name: 'Projects List',
            component: ProjectListPage,
            meta: {
              permission: {
                subject: 'ERP_PROJECTS',
                action: ['READ_OWN', 'READ_ALL'],
              },
            },
          },
          {
            path: 'project-calculation/project/:project/month/:month',
            name: 'Project Calculation',
            component: ProjectCalculationPage,
            meta: {
              permission: {
                subject: 'ERP_PROJECT_CALCULATIONS',
                action: ['READ_OWN', 'READ_ALL'],
              },
              key: 'projectCalculation',
            },
          },
          {
            path: 'project-overview/project/:project',
            name: 'Project Overview',
            component: ProjectOverviewPage,
            meta: {
              permission: {
                subject: 'ERP_PROJECTS',
                action: ['READ_OWN', 'READ_ALL'],
              },
            },
          },
        ],
      },
      {
        path: 'HR',
        name: 'HR',
        component: wrapperComponent,
        children: [
          {
            path: 'employees-internal',
            name: 'Internal Collaborators',
            component: CollaboratorInternalListPage,
            meta: {
              permission: {
                subject: 'ERP_COLLABORATORS_INTERNAL',
                action: 'READ_ALL_PROPERTIES',
              },
              key: 'employees-internal',
            },
          },
          {
            path: 'employees-external',
            name: 'External Collaborators',
            component: CollaboratorExternalListPage,
            meta: {
              permission: {
                subject: 'ERP_COLLABORATORS_EXTERNAL',
                action: 'READ_ALL_PROPERTIES',
              },
              key: 'employees-external',
            },
          },
          {
            path: 'collaborator-skills',
            name: 'Collaborators skills',
            component: CollaboratorSkillsPage,
            meta: {
              permission: {
                subject: 'ERP_COLLABORATOR_SKILLS',
                action: 'READ',
              },
            },
          },
        ],
      },
      {
        path: 'finance',
        name: 'Finance',
        component: wrapperComponent,
        children: [
          {
            path: 'bank-transactions',
            name: 'Bank transactions',
            component: BankTransactionListPageV2,
            meta: {
              permission: {
                subject: 'FNS_BANK_TRANSACTIONS',
                action: 'READ',
              },
              key: 'bankTransactionsV2',
            },
          },
          {
            path: 'employee-costs',
            name: 'Collaborator costs',
            component: CollaboratorCostListPage,
            meta: {
              permission: {
                subject: 'ERP_COLLABORATOR_COST',
                action: 'READ',
              },
            },
          },
          {
            path: 'costs-planning',
            name: 'Costs planning',
            component: CostsPlanningPage,
            meta: {
              permission: {
                subject: 'ERP_OPERATING_COSTS',
                action: 'READ',
              },
            },
          },
          {
            path: 'company-calculation',
            name: 'Company calculation',
            component: CompanyCalculationPage,
            meta: {
              permission: {
                subject: 'ERP_COMPANY_CALCULATION',
                action: 'READ',
              },
            },
          },
          {
            path: 'invoices',
            name: 'Invoices',
            component: InvoiceListPage,
            meta: {
              permission: {
                subject: 'ERP_INVOICES',
                action: 'READ',
              },
              key: 'invoices',
            },
          },
          {
            path: 'asset-list',
            name: 'Assets',
            component: AssetListPage,
            meta: {
              permission: {
                subject: 'ASS_ASSETS',
                action: 'READ',
              },
            },
          },
          {
            path: 'project-overall-calculation',
            name: 'Project overall calculation',
            component: ProjectOverallCalculationPage,
            meta: {
              permission: {
                subject: 'ERP_PROJECT_CALCULATIONS',
                action: ['READ_OWN', 'READ_ALL'],
              },
            },
          },
        ],
      },
      {
        path: 'analysis',
        name: 'Analysis',
        component: wrapperComponent,
        children: [
          {
            path: 'worklog-analysis',
            name: 'WorkLog analysis',
            component: WorkLogAnalysisPage,
            meta: {
              permission: {
                subject: 'ERP_WORKLOG_ANALYSIS',
                action: 'READ',
              },
              helpComponent: WorkLogIssueHelp,
              key: 'worklogAnalysis',
            },
          },
          {
            path: 'costs-analysis',
            name: 'Costs analysis',
            component: CostCategoryAnalysisPage,
            meta: {
              permission: {
                subject: 'ERP_COSTS_ANALYSIS',
                action: 'READ',
              },
            },
          },
          {
            path: 'invoice-reminders',
            name: 'Invoice reminders',
            component: InvoiceReminderListPage,
            meta: {
              permission: {
                subject: 'ERP_INVOICE_REMINDER',
                action: 'READ',
              },
            },
          },
          {
            path: 'invoice-analysis',
            name: 'Invoice analysis',
            component: InvoiceAnalysisPage,
            meta: {
              permission: {
                subject: 'ERP_INVOICE_ANALYSISS',
                action: 'READ',
              },
              helpComponent: InvoiceAnalysisHelp,
            },
          },
          {
            path: 'company-work-performance/:year',
            name: 'Company work performance',
            component: CompanyWorkPerformancePage,
            meta: {
              permission: {
                subject: 'ERP_COMPANY_WORK_PERFORMANCE',
                action: 'READ',
              },
            },
          },
          {
            path: 'company-calculation-analysis/:year',
            name: 'Company calculation analysis',
            component: CompanyCalculationAnalysisPage,
            meta: {
              permission: {
                subject: 'ERP_COMPANY_CALCULATION_ANALYSIS',
                action: 'READ',
              },
            },
          },
        ],
      },
      {
        path: 'planning',
        name: 'Planning',
        component: wrapperComponent,
        children: [
          {
            path: 'collaborator-work-plan',
            name: 'Collaborator work plan',
            component: CollaboratorWorkPlanPageV2,
            meta: {
              permission: {
                subject: 'ERP_COLLABORATOR_WORK_PLAN',
                action: 'READ',
              },
            },
          },
          {
            path: 'employee-project-plan-v2',
            name: 'Collaborator project plan',
            component: CollaboratorProjectPlanPageV2,
            meta: {
              permission: {
                subject: 'ERP_COLLABORATOR_PROJECT_PLAN',
                action: 'READ',
              },
              helpComponent: ProjectPlanHelp,
              key: 'projectPlanV2',
            },
          },
        ],
      },
    ],
  },
];

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err);
// };

export const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from) => {
  if (to.path.startsWith('/storybook')) {
    return false;
  }

  const { isLogged, initialized } = useAuthentication();
  const { getConfig, setConfig } = useConfig();

  if (to.matched[0]?.path === '/app' && !isLogged.value) {
    if (initialized.value) {
      // TFPL-1240
      // window.location.href = `${getConfig().ULA_URL}/?organization=${
      //   getConfig().TENANT ?? DEFAULT_PERSONAL_SETTINGS.selectedTenantCode
      // }&application=ERP`;
    }
    if (from.fullPath === '/') {
      setConfig({ ...getConfig(), REDIRECT_URL: to.fullPath });
    }
    return false;
  }

  if (to.name) {
    document.title = to.name as string;
  }
});

export const navigateSubmenuItem = (submenuItem: SubmenuItem) => {
  if (submenuItem.type === 'static') {
    router.push(submenuItem.link);
  } else {
    const link = `/google-doc/document/${submenuItem.id}`;
    router.push(link);
  }
};

export const navigateSubmenuItemNewWindow = (submenuItem: SubmenuItem) => {
  if (submenuItem.type === 'static') {
    const route = router.resolve({ path: submenuItem.link });
    window.open(route.href, '_blank');
  } else {
    const link = `/google-doc/document/${submenuItem.id}`;
    const route = router.resolve({ path: link });
    window.open(route.href, '_blank');
  }
};
