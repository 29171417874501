import axios from 'axios';
import { ref } from 'vue';

import { TFPL_ID_TOKEN } from '@/constants';
import { useUla } from '@/composables/useUla';

export const useInterceptor = () => {
  const { getIdToken, logout } = useUla();

  const requestInterceptorId = ref<number | null>(null);
  const unauthorizedInterceptorId = ref<number | null>(null);

  const setupRefreshAuthTokenInterceptor = () => {
    if (requestInterceptorId.value !== null) {
      axios.interceptors.request.eject(requestInterceptorId.value);
    }

    requestInterceptorId.value = axios.interceptors.request.use(
      async config => {
        const token = await getIdToken();
        config.headers[TFPL_ID_TOKEN] = token;
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
  };

  const setupUnauthorizedInterceptor = () => {
    if (unauthorizedInterceptorId.value !== null) {
      axios.interceptors.response.eject(unauthorizedInterceptorId.value);
      unauthorizedInterceptorId.value = null;
    }

    unauthorizedInterceptorId.value = axios.interceptors.response.use(
      response => response,
      async error => {
        if (error.response.status === 401) {
          await logout();
        }
        return Promise.reject(error);
      },
    );
  };

  const removeRefreshAuthTokenInterceptor = () => {
    if (requestInterceptorId.value !== null) {
      axios.interceptors.request.eject(requestInterceptorId.value);
      requestInterceptorId.value = null;
    }
  };

  const removeUnauthorizedInterceptor = () => {
    if (unauthorizedInterceptorId.value !== null) {
      axios.interceptors.response.eject(unauthorizedInterceptorId.value);
      unauthorizedInterceptorId.value = null;
    }
  };

  return {
    setupRefreshAuthTokenInterceptor,
    setupUnauthorizedInterceptor,
    removeRefreshAuthTokenInterceptor,
    removeUnauthorizedInterceptor,
  };
};
