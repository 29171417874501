import { useConfig } from '@/composables/useConfig';
import { useLogger } from '@/composables/useLogger';

export const useUla = () => {
  const { getConfig } = useConfig();
  const { logger } = useLogger();

  const sendMessageToUla = (message: string) => {
    const iframe = document.querySelector('#ulaIframe') as HTMLIFrameElement | null;
    iframe?.contentWindow?.postMessage(message, getConfig().ULA_URL);
  };

  const loadUserInfo = (): void => {
    sendMessageToUla('getUserInfo');
  };

  const logout = (): Promise<void> => {
    sendMessageToUla('logout');
    return new Promise(resolve => {
      window.addEventListener(
        'message',
        e => {
          if (e.origin === getConfig().ULA_URL && e.data.name === 'ula-logout') {
            logger.info('>>> ULA logout completed');
            resolve();
          }
        },
        { once: true },
      );
    });
  };

  const getIdToken = (): Promise<string | null> => {
    sendMessageToUla('getIdToken');
    return new Promise(resolve => {
      window.addEventListener(
        'message',
        e => {
          if (e.origin === getConfig().ULA_URL && e.data.name === 'ula-id-token') {
            resolve(e.data.idToken ?? null);
          }
        },
        { once: true },
      );
    });
  };

  return {
    loadUserInfo,
    logout,
    getIdToken,
  };
};
