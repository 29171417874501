export default {
  '/': '/',
  '%': '%',
  logIn: 'Login',
  selectedMonths: 'Selected months',
  forSale: 'For sale',
  soldOut: 'Sold out',
  reportCapacityActiveFilter: 'Showing 1 of 20, filter applied: {skill}',
  countedInCompanyCalculation: 'Counted in company calculation',
  signingIn: 'Signing in...',
  longSignInMessage: "Hang tight! We're securely logging you in. This may take a moment.",
  buttons: {
    cancel: 'Cancel',
    confirm: 'Confirm',
    delete: 'Delete',
    save: 'Save',
    create: 'Create',
    update: 'Update',
    help: 'Help',
    openInNewWindow: 'Open in new window',
    actualNow: 'Mark profile as up to date',
    goToV2: 'Go to v2',
    goToV1: 'Go to v1',
    expandRows: 'Expand rows',
    collapseRows: 'Collapse rows',
    markUpToDate: 'Mark as up to date',
    redirectToProfile: 'Redirect to profile',
    copyToClipboard: 'Copy to clipboard',
    selectAll: 'All',
    selectNone: 'None',
  },
  filter: {
    search: 'Quick search',
    yes: 'Yes',
    no: 'No',
  },
  toast: {
    success: 'Success',
    error: 'Error',
    itemDidntFound: "Item didn't found",
    copiedToClipboard: 'Copied to clipboard',
  },
  settings: {
    settings: 'Settings',
    useAdminApp: 'Settings is moved to Admin app',
  },
  validation: {
    required: 'Value is required',
    before: 'Value is before {name}',
    after: 'Value is after {name}',
    requiredShort: 'Required',
    number: 'Value must be number',
    email: 'Value must be valid e-mail',
    projectNameIsAlreadyTaken: 'Project name is already taken',
    projectAbbreviationIsAlreadyTaken: 'Project abbreviation is already taken',
    emailIsAlreadyTaken: 'E-mail is already taken',
    phoneIsAlreadyTaken: 'Phone is already taken',
    jiraIdIsAlreadyTaken: 'Jira id is already taken',
    noPermissionsForPosition: 'Insufficient permissions for position changes',
    noUniqueOrganizationPosition: 'There must be only one position in the same organization',
  },
  asset: {
    collaboratorSearch: 'Collaborator',
    search: 'Search assets',
    collaborator: 'Collaborator',
    name: 'Name',
    description: 'Description',
    category: 'Category',
    buyPriceWithoutVat: 'Buy price (without VAT)',
    buyDate: 'Buy date',
    owner: 'Owner',
    protocolSigned: 'Protocol signed',
    note: 'Note',
    create: 'Add new asset',
    edit: 'Edit asset',
    delete: 'Delete',
    saveSuccess: 'Asset saved',
    czk: 'CZK',
    assetNumber: 'Asset number',
    location: 'Location',
    place: 'Place',
    brand: 'Brand',
    uuid: 'Uuid',
    categoryName: 'Category',
    locationName: 'Location',
    collaboratorName: 'Collaborator',
  },
  bankTransaction: {
    uuid: 'Uuid',
    description: 'Description',
    amount: 'Amount',
    amountWithoutVat: 'Amount (Without VAT)',
    amountLeft: 'Amount left on transaction',
    date: 'Date',
    userNotes: 'User notes',
    authorName: 'Author name',
    subjectName: 'Subject name',
    subjectBic: 'Subject BIC',
    subjectAccountNumber: 'Subject account number',
    extra: 'Extra',
    checked: 'Checked',
    companyCalculationDate: 'Company calculation month',
    minAmount: 'Amount is less than',
    maxAmount: 'Amount is more than',
    bankTransactionDetail: 'Bank transaction detail',
    accountNumber: 'Account number',
    paymentAuthor: 'Payment author',
    author: 'Payment author',
    deductibleVatPercent: 'Deductible VAT %',
    id: 'ID',
    comment: 'Comment',
    orderId: 'Order id',
    type: 'Type',
    partnerNumber: 'Partner number',
    extraInformation: 'Extra information',
    bankTransactionList: 'Bank transaction list',
    constantSymbol: 'Constant symbol',
    lookingForBankTransactions: 'Looking for bank transactions...',
    messageForPartner: 'Message for partner',
    partnerBankAccountNumber: "Partner's bank account number",
    partnerBankBic: "Partner's bank BIC",
    partnerBankCode: "Partner's bank code",
    partnerBankName: "Partner's bank name",
    partnerName: "Partner's name",
    selectedBankAccount: 'Selected bank account',
    specificSymbol: 'Specific symbol',
    split: 'Split',
    splitTransaction: 'Split transaction',
    isTransactionSplit: 'Split transaction',
    thisAccountDoesNotAppearToContainAnyTransactionYet: 'This account does not appear to contain any transactions yet',
    transactionDate: 'Transaction date',
    userIdentification: 'User identification',
    variableSymbol: 'Variable symbol',
    selectInitialDateForSync: 'Select initial date for sync',
    transactionDoesNotContainMoreDetails: "Transaction doesn't contain more details",
    filterBankTransactions: 'Filter bank transactions',
    bankTransactionCostCategory: 'Cost category',
    searchBankTransActions: 'Search bank transactions',
    selectAllBankAccounts: 'Select all bank accounts',
    costCategories: 'Cost categories',
    bankAccount: 'Bank account',
    dateRangeFilter: 'Date range filter',
    transactionDateRange: 'Transaction date range',
    costCategory: 'Cost category',
    bankAccountLoadError: 'Could not load bank accounts',
    getRowsError: 'Could not load bank transactions',
    findBankAccountIdError: 'Could not find id of a bank account',
    findCostCategoryIdError: 'Could not find id of a cost category',
    transactionUpdateError: 'There was a problem while updating the transaction',
    getBankTransactionDetailsError: 'Could not load bank transaction details',
    currency: 'Currency',
  },
  collaboratorSkills: {
    name: 'Name',
    position: 'Position',
    skillValuesUpdatedAt: 'Updated at',
    addNewSkill: 'Add new',
  },
  costCategories: {
    wages: 'Wages',
    outsource: 'Outsource',
    hr_internal: 'HR internal',
    hr_external: 'HR external',
    equipment: 'Equipment',
    infrastructure_internal: 'Infrastructure internal',
    infrastructure_external: 'Infrastructure external',
    administration: 'Administration',
    sales: 'Sales',
    rtsg: 'RTSG',
    other: 'Other',
    office: 'Office',
    vat: 'VAT',
    notCategorized: 'Not categorized',
    getDataError: 'Could not get cost categories data',
  },
  invoiceAnalysis: {
    filterInvoices: 'Filter invoices',
    help: 'All invoices values are recomputed to CZK',
    selectedProject: 'Selected project',
    paidSum: 'PAID:',
    pendingSum: 'PENDING:',
    unpaidSum: 'UNPAID:',
    totalSum: 'SUM:',
  },
  workLogAnalysis: {
    issuesStatistics: 'Issues statistics',
    actions: 'Actions',
    fullName: 'Name',
    filterDates: 'Filter dates',
    selectWorkLogIssueState: 'Select issue status',
    selectWorkLogIssueType: 'Select issue type',
    selectProject: 'Select project',
    employeeSearch: 'Employee',
    ticketKey: 'Ticket key',
    ticketTitle: 'Ticket title',
    employee: 'Employee',
    updatedAt: 'Work log updated',
    workedAt: 'Work start time',
    loggedTime: 'Logged time',
    issueType: 'Issue type',
    issueStatus: 'Issue status',
    createdAt: 'Work log created',
    help: {
      syncText: 'WorkLogs issues are synced every 10 minutes.',
      openJiraTicket: 'You can open jira ticket by clicking on ticket key in table.',
      logTimeFormats: 'Logged time: 1w = 5d, 1d = 8h, 1h = 60m',
    },
    markIssueAsResolved: 'Mark issue as resolved',
    markIssueAsNew: 'Mark issue as new',
    fetchDataError: 'Could not load table data',
  },
  employeeCost: {
    month: 'Month',
    history: 'Collaborator costs history',
    help: {
      buttonsOfRow: 'Buttons of row',
      buttons: {
        edit: 'Edit - edit selected row',
        copy: 'Copy - copy selected row from previous month',
      },
      valuesCopying:
        'First day of every month, values for all employees are copied from previous month to current one.',
      totalCostsPerHour:
        'Total costs per hour = total wage cost per hour + (sum operation cost per month / sum working days for employees on project per month)',
    },
    editTitle: 'Collaborator cost',
    employee: {
      firstName: 'First name',
      lastName: 'Last name',
      position: 'Position',
    },
    setFromMonth: 'Set costs from month',
    setToMonth: 'Set costs to month',
    wageSendingCostsPerMonth: 'Wage sending costs per month',
    totalWageCostsPerHour: 'Total wage costs per hour',
    totalCostsPerHour: 'Total cost per hour',
    primaryLegalType: 'Primary legal type',
    primaryLegalTypeWithNote: 'Primary legal type (used for projects profit calculation)',
    workloadHours: 'Workload hours',
    hppWorkloadHours: 'HPP - Workload hours',
    dppWorkloadHours: 'DPP - Workload hours',
    zlWorkloadHours: 'ZL - Workload hours',
    grossWagePerMonth: 'Gross wage per month',
    hppGrossWagePerMonth: 'HPP - Gross wage per month',
    grossWagePerHour: 'Gross wage per hour',
    dppGrossWagePerHour: 'DPP - Gross wage per hour',
    zlGrossWagePerHour: 'ZL - Gross wage per hour',
    standardBonus: 'Standard bonus',
    hppStandardBonus: 'HPP - Standard bonus',
    dppStandardBonus: 'DPP - Standard bonus',
    zlStandardBonus: 'ZL - Standard bonus',
    oneTimeBonus: 'One time bonus',
    hppOneTimeBonus: 'HPP - One time bonus',
    dppOneTimeBonus: 'DPP - One time bonus',
    zlOneTimeBonus: 'ZL - One time bonus',
    vacationDayCount: 'Vacation day count',
    hppVacationDayCount: 'HPP - Vacation day count',
    mealVoucherCosts: 'Meal voucher costs',
    hppMealVoucherCosts: 'HPP - Meal voucher costs',
    filterDateMonth: 'Collaborator cost date month',
    edit: 'Edit collaborator cost',
    copy: 'Copy collaborator cost',
    previousMonthValuesNotFound: 'Previous month values not found',
    search: 'Search collaborator costs',
    formIsNotValid: 'Form is not valid',
    employeePositions: 'Employee positions',
    getListError: 'Could not get collaborator cost list',
    getEmployeePositionsError: 'Could not get employee positions',
  },
  collaboratorWorkPlan: {
    firstName: 'First name',
    lastName: 'Last name',
    position: 'Position',
    saveCollaboratorWorkPlan: 'Save collaborator work plan',
    sumPlannedCountedInAverageCostsPerHour: '∑ included in planned average costs per hour:',
    workDaysMD: 'Working MD',
    paidVacationMD: 'Vacation MD',
    unpaidVacationMD: 'Unpaid vacation MD',
    lookingForCollaboratorWorkPlans: 'Looking for collaborator work plans...',
    noCollaboratorWorkPlan: 'No collaborator work plan...',
    countInAverageCostPerHour: 'Include in average cost per hour',
    filterDateMonth: 'Collaborator work plan date month',
    workDaysSumInMD: '∑ planned working:',
    paidVacationSumInMD: '∑ planned vacation:',
    unpaidVacationSumInMD: '∑ planned unpaid vacation:',
    countableForAverageOperationCosts: '∑ of countable for average operating costs:',
    MD: 'MD',
    hour: 'h',
    successSaved: 'Values ​​saved successfully',
    baseWorkingMD: 'Base working MD',
    plannedWorkingMD: 'Planned working MD',
    plannedVacationMD: 'Planned vacation MD',
    plannedUnpaidVacationMD: 'Planned unpaid vacation MD',
    realWorkingMD: 'Real working MD',
    realVacationMD: 'Real vacation MD',
    realUnpaidVacationMD: 'Real unpaid vacation MD',
    includedInProjectPlan: 'Count in average operating cost',
    setBaseWorkingMd: 'Set base working days for all in current month',
    baseWorkingDaysDialog: {
      title: 'Do you want to set base working days?',
      text: 'Base working days will be set for all collaborators in the chosen month.',
    },
    baseWorkingDaysSuccessSaved: 'Base working days have been set for all collaborators.',
  },
  projectPlan: {
    save: 'Save project plan',
    saveTitle: 'Save',
    planSaved: 'Project plan saved',
    planNoteUpdated: 'Project note updated',
    salesNoteNeedsToBeUpdated: 'Sales note needs to be updated',
    updatedBySomeoneElse: 'Data has been updated by someone else, please refresh the page.',
    table: {
      occupancy: {
        label: 'Occupancy',
        billable: 'Billable',
        billableTooltipValues: '({totalBillable} / {totalAvailableCapacity}) * 100 = {percentageResult}',
        billableTooltipDescription: '(∑ billable planned work / ∑ employees available capacity) * 100',
        planned: 'Planned',
        plannedTooltipValues: '({totalPlanned} / {totalAvailableCapacity}) * 100 = {percentageResult}',
        plannedTooltipDescription: '(∑ all planned work / ∑ employees available capacity) * 100',
        availableCapacity: 'MD left',
      },
      project: {
        label: 'Project',
        requiredCapacity: 'Required capacity',
        requiredCapacityAbbreviation: 'Req. cap.',
        projectHasNoOrderForThisMonth: 'Project has no order for this month',
        projectOrderHaveNotClientSignDateForThisMonth: 'Some project orders do not have client sign date',
        totalCapacityTooltip: '∑ {total} MD',
        percentageCapacityTooltip: '{perc}% ({required}/{total})',
        orderLink: 'Orders',
        categories: {
          INTERNAL: 'Internal project',
          OUTSOURCE: 'Outsource project',
          INHOUSE: 'In house project',
        },
      },
      employee: {
        label: 'Collaborator',
        position: 'Position',
        availableCapacity: 'Available capacity',
        categories: {
          INTERNAL_EMPLOYEE: 'Internal collaborator',
          EXTERNAL_EMPLOYEE: 'External collaborator',
        },
      },
      unusedCapacity: {
        label: 'Unused capacity',
      },
      internalLabel: 'Internal project',
      manager: 'Manager',
    },
    help: {
      projectItems: 'Table shows projects which are active.',
      employeeItems: 'Table shows employees which are included in project planning by their position.',
      overCapacity: "Capacity can go over it's limit, e.g.: an employee worked overtime.",
    },
    noSkillsInfo: 'No skills filled in',
    datePicker: 'Project plan month',
  },
  operatingCost: {
    sumLabel: 'SUM:',
    totalCostsPerHourLabel: 'Total costs per hour:',
    workHours: 'Work hours:',
    editHours: 'Edit',
    deleteItem: 'Delete item',
    datePickerLabel: 'Operating cost month',
    items: 'Items',
    table: {
      name: 'Cost name',
      value: 'Value',
    },
    itemCreated: 'Item created',
    itemUpdated: 'Item updated',
    itemDeleted: 'Item deleted',
    itemsCopied: 'Items copied',
    copy: 'Copy',
    copyTooltip: 'Copy all from last month',
    copyDialog: {
      title: 'Do you really want to copy?',
      text: 'The selected month will be replaced by items from the last month.',
    },
    help: {
      copy: 'Copying from the last month will replace the current month items.',
      update: 'Item will be updated automatically after navigating out from the fields.',
      create: 'After filling all fields, item can be created by pressing a key Enter.',
    },
  },
  costsPlanning: {
    plannedCostPerWorkHours: 'Avg. planned cost per work h.',
    plannedWorkHours: 'Planned work hours',
    datePicker: 'Year',
    save: 'Save',
    import: 'Import',
    exportCsv: 'Export csv',
    categoryName: 'Category name',
    subCategoryName: 'Sub-category name',
    yearSum: 'Year sum',
    sumOfMonth: 'Planned costs per month',
    costPlanSaved: 'Cost plan saved',
    cannotSaveCostPlan: "Can't save cost plan. Try again later",
    unsavedChangesAlert:
      'You have recently made some changes that have not been saved to the server. Before leaving this page, make sure you have saved the changes',
    importDialog: {
      title: 'Import costs',
      spreadsheetUrl: 'Spreadsheet url',
      sheetName: 'Sheet name',
      importMonths: 'Selected months',
      previewImport: 'Preview import',
      executeImport: 'Execute import',
      getImportError: 'Import from google spreadsheet failed',
      saveImportedDataError: 'Saving imported data failed',
      saveImportedDataSuccess: 'Saving imported data was successful',
      hideErrors: 'Hide Errors',
      showErrors: 'Show Errors',
      unmatchedExistingData: 'Unmatched Existing Data Subcategories',
      unmatchedImportedData: 'Unmatched Imported Data Subcategories - BLOCKS IMPORT',
    },
  },
  companyCalculation: {
    category: {
      name: 'Cost category',
    },
    getListError: 'Could not get company calculation list',
    noNonEmptyCostSubCategories: 'There are no non empty cost subcategories for cost category: ',
    countInCompanyCalculation: 'Count in calculation',
    notCountInCompanyCalculation: 'Not count in calculation',
    investmentCostsDiff: '∑ Cost diff',
    costsBalance: '∑ Real costs',
    totalPlannedCost: '∑ Planned costs',
    plannedCost: 'Planned costs',
    realCost: 'Real costs',
    realCostTooltip: 'Sum by bank transactions',
    correction: 'Cost corrections',
    correctionTooltip: 'Pozitivní hodnota znamená větší náklady, negativní znamená menší náklady',
    costDiff: 'Cost difference',
    datePickerLabel: 'Company calculation month',
    companyProfit: 'Company profit',
    cumulativeCompanyProfit: 'Cumulative company profit',
    projectsProfit: '∑ Projects profit',
    invoiceRevenues: '∑ Invoice revenues',
    projectsRevenues: 'Revenue',
    notCategorizedAmount: 'Not categorized costs',
    calculationCorrection: '∑ Corrections (WITHOUT cost category)',
    noteCorrection: 'Note & correction',
    virtualCorrection: 'Calculated correction for transaction split',
    help: {
      projectsProfit: 'Suma profitů všech projektů',
      totalPlannedCost: 'Suma plánovanách nákladů',
      companyProfit: 'Profit společnosti',
      totalCosts: 'Suma nákladů počítaných do kalkulace, dle bankovních transakcí',
      investmentCostsDiff:
        'Rozdíl mezi plánovanými a reálnými náklady, minusová hodnota znamená, že jsme utratili méně, plusová, že jsme utratili více, než bylo v plánu. Včetně korekcí s cost category',
      countProjectsProfits:
        'company profit = ∑(planned costs) - ∑(real costs) + ∑(corrections WITH cost category) + ∑(project profits) + ∑(corrections WITHOUT cost category)',
      countInvoiceRevenues:
        'company profit = - ∑(real costs) + ∑(corrections WITH cost category) + ∑(invoice revenues) + ∑(corrections WITHOUT cost category)',
      sumCorrectionsWithCostCategory:
        'Součet korekcí s kategorií, jak těch manuálně zadaných, tak splity bankovních transakcí',
      sumCorrectionsWithoutCostCategory: 'Součet korekcí bez kategorií',
      sumProjectRevenues: 'Suma příjmů na projektech, zadaných v kalkulacích projektů',
      invoiceRevenues:
        'Suma fakturovaných částek v daném měsící dle DUZP bez DPH. EUR faktury jsou přepočítány dle kurzu ČNB pro daný den',
    },
    sumCorrectionsWithCostCategory: '∑ Corrections (WITH cost category)',
    costs: 'Costs',
    revenues: 'Revenues',
    sumProjectRevenues: '∑ Projects revenues',
    profit: 'Profit',
    calculationModel: 'Calculation model',
    companyCalculationNoteDialog: {
      value: 'Value',
      actions: 'Actions',
      bankTransactionSplitInfo: 'Correction for bank transaction: {id}',
    },
    bankTransactionsDialog: {
      name: 'Bank transactions',
    },
    correctionsDialog: {
      header: 'Corrections',
      name: 'Name',
      value: 'Value',
      category: 'Category',
    },
    countProjectsProfits: 'PROJECTS profits',
    countInvoiceRevenues: 'INVOICES revenues',
    count: 'Count',
  },
  projectCalculation: {
    edit: 'Edit project',
    timeCorrection: 'Time correction [h]',
    selectedProject: 'Selected project',
    costs: 'Costs from logs',
    additionalCosts: 'Additional costs',
    revenues: 'Revenues',
    profitCZK: 'Profit',
    profitPercent: 'Profit %',
    discountCZK: 'Discount CZK',
    month: 'Selected month',
    projectRevenues: 'Project revenues',
    workType: 'Work type',
    description: 'Description',
    pricePerHour: 'Price per hour',
    workedTime: 'Worked hours',
    ticketKey: 'Ticket key',
    ticketTitle: 'Ticket title',
    timeSpendInHours: 'Time spend [h]',
    workedHoursSum: 'Worked sum [h]',
    costPerHour: 'Cost per hour',
    totalEmployeeCost: 'Total cost',
    calculationUpdated: 'Calculation updated',
    revenueSaved: 'Revenue saved',
    revenueDeleted: 'Revenue deleted',
    noWorkLogs: 'No worklogs logged',
    totalWorked: 'Total worked MD',
    totalBilled: 'Total billed MD',
    totalWorkedWithCorrection: 'Total worked MD with correction',
    moveTicketToProject: 'Move ticket to project',
    ticketMoved: 'Ticket moved',
    from: 'from:',
    to: 'to:',
    setOriginalProject: 'Set original project',
    reportSend: 'Report sent',
    reportApproved: 'Report approved',
    invoiceSent: 'Invoice sent',
    billingStatus: 'Billing status',
    projectManager: 'Project manager',
    noProjectManager: 'No Project manager',
    note: 'Note',
    locked: 'Locked',
    notCountThisTicketInCalculation: 'Not count this ticket in calculation',
    countThisTicketInCalculation: 'Count this ticket in calculation',
    price: 'Price',
    priceTooltip: 'Price without VAT',
    total: 'Total',
    copyToClipboardWithoutWorkType: 'Copy to clipboard without work type',
    deleteThisRevenue: 'Delete this revenue',
    createNewRevenue: 'Create new revenue',
    generateReport: 'Generate report',
    copyRevenuesFromLastMonth: 'Copy revenues from last month',
    revenuesCopied: 'Revenues copied',
    noRevenuesToCopy: 'There is no revenues to copy from last month',
    projectCalculationLocked: 'Project calculation is locked',
    workLogs: 'Worklogs detail for project {projectName}, user {employeeName} at {selectedDate}',
    workLogsDialog: {
      date: 'Date',
      spentTime: 'Logged time',
      ticket: 'Ticket',
      summary: 'Description',
    },
  },
  projectOverallCalculation: {
    getListError: 'Could not get project list',
    showZeroProfitProjects: 'Show zero profit projects',
    type: 'Type',
    category: 'Category',
    averageProfit: 'Average projects profit',
    filterDateMonth: 'Month',
    costs: 'Costs CZK',
    revenues: 'Revenues CZK',
    profit: 'Profit CZK',
    profitInPercentage: 'Profit %',
    sumProfit: 'SUM profit:',
    sumWorkedTime: 'SUM worked time:',
    sumRevenues: 'SUM revenues:',
    workedTimeInSec: 'Worked hours',
    billedTimeInSec: 'Billed hours',
    billingStatus: 'Billing status',
    projectManager: {
      name: 'Project manager',
    },
    salesManager: {
      name: 'Sales manager',
    },
    locked: 'Locked',
  },
  salesProjectsStatistics: {
    sumRevenues: 'SUM revenues:',
    filterDateMonth: 'Month',
    revenues: 'Revenues',
    clientName: 'Client name',
    reportSent: 'Report send',
    showZeroProfitProjects: 'Show zero profit projects',
  },
  hour: 'Hour',
  help: 'Help',
  name: 'Name',
  description: 'Description',
  actions: 'Actions',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'E-mail',
  password: 'Password',
  userGroup: 'User group',
  role: 'Role',
  icon: 'Icon',
  home: 'Home',
  logout: 'Logout',
  openInNewWindow: 'Open in a new window',
  loginWithGoogle: 'Login with Google',
  login: 'LOG IN',
  loginError: 'Login error - please check credentials',
  order: 'Order',
  pickIconFrom_url_: 'Pick icon from {0}.',
  link: 'Link',
  category: 'Category',
  canView: 'Can view',
  canEdit: 'Can edit',
  dataWillBeSynchronizedFromGoogleAfterFirstLogin:
    'First name, last name and avatar will be synchronized from Google after first login',
  token: 'Token',
  projectsList: 'Projects',
  amount: 'Amount',
  type: 'Type',
  comment: 'Comment',
  author: 'Author',
  currency: 'Currency',
  czk: 'CZK',
  sync: 'Sync',
  dash: '-',
  permissions: 'User permissions',
  dateRange: 'Date Range',
  textEditor: {
    bold: 'Bold',
    italic: 'Italic',
    strike: 'Strike',
    underline: 'Underline',
    code: 'Code',
    paragraph: 'Paragraph',
    heading1: 'Heading 1',
    heading2: 'Heading 2',
    heading3: 'Heading 3',
    bulletList: 'Bullet list',
    orderList: 'Order list',
    codeBlock: 'Code block',
    horizontalRule: 'Horizontal rule',
    undo: 'Undo',
    redo: 'Redo',
  },
  organization: {
    name: 'Organization name',
    url: 'Organization URL',
    create: 'Create new organization',
    delete: 'Delete organization',
    edit: 'Edit organization',
    active: 'Active',
    managed: 'Managed',
    search: 'Search organizations',
    saveSuccess: 'Organization saved',
    deleteSuccess: 'Organization deleted',
    deleteOrganization: 'Delete Organization',
    organizationExistsError: 'Organization with this name or code or URL already exists',
    crmLink: 'CRM link',
    projects: 'Projects',
    code: 'Code',
    organizationIsManaged: 'Organization is managed',
    id: 'Organization ID',
    uuid: 'Organization UUID',
  },
  employee: {
    personalSection: 'Personal',
    companySection: 'Company',
    profileSection: 'Profile',
    positions: 'Positions',
    employees: 'Collaborators',
    employeeProfile: 'Collaborator Profile',
    create: 'Create new employee',
    search: 'Search collaborator',
    positionSelect: 'Select positions',
    edit: 'Edit collaborator',
    firstName: 'First name',
    warningsCol: 'Warnings',
    lastName: 'Last name',
    generatedId: 'Id',
    uuid: 'Uuid',
    privateEmail: 'Private e-mail',
    workEmail: 'Work e-mail',
    hrNotes: 'HR Notes',
    insuranceCompany: 'Insurance company',
    contactPersonPhone: 'Contact person name + phone number',
    companyIdentificationNumber: 'CIN - IČO',
    contactsAddress: 'Contacts address',
    phone: 'Phone',
    birthdate: 'Birthdate',
    permanentResidence: 'Permanent residence / Business address',
    personalIdentificationNumber: 'Personal identification number',
    education: 'Education',
    profileUrlUpdatedAt: 'Last profile url update',
    updatedByAndWhen: 'Updated {date} by {employee}',
    needsToBeUpdated: 'Profile needs to be updated, last change is older than 3 months',
    profileDoesntExist: "Collaborator profile doesn't exist",
    confirmProfileUpToDate: {
      title: 'Collaborator Profile is outdated',
      confirm: 'Mark profile as up to date',
      success: 'Profile marked as up to date',
    },
    position: {
      name: 'Position',
    },
    organization: {
      name: 'Organization',
    },
    legalType: 'Legal type',
    BOZP: 'BOZP',
    medicalCheck: 'Medical check',
    bankAccountNumber: 'Bank account number',
    workStart: 'Work start',
    workEnd: 'Work end',
    jiraId: 'Jira id',
    organizations: 'Organizations',
    lookingForEmployees: 'Looking for employess...',
    noEmployeeYet: 'No employee yet.',
    saveSuccess: 'Employee saved',
    saveSkillsSuccess: 'Employee skills saved',
    deleteSuccess: 'Employee deleted',
    deleteFailed: 'Employee delete failed',
    editEmployeeSkills: 'Edit employee skills',
    removeEmployeeSkill: 'Remove employee skill',
    addEmployeeSkill: 'Add employee skill',
    editEmployee: 'Edit employee',
    deleteEmployee: 'Delete employee',
    employeeSkillSlider: {
      min: '5 is the worst',
      max: '1 is the best',
    },
    noSkillFound: 'No skill with value 1 to 4 found for employee',
    assets: 'Employee assets',
    fullName: 'Full name',
    profileUrl: 'Profile URL',
    activeEmployees: 'Active employees',
    active: 'Active',
    leavingChecklist: 'Leaving Checklist',
    warnings: {
      shouldHaveBozpAndMedicalCheck: 'Should have BOZP and medical check',
      shouldHaveBozp: 'Should have BOZP',
      shouldHaveMedicalCheck: 'Should have medical check',
      shouldHaveBaseWorkingDaysCountForCurrentMonth: 'Should have base working days count for current month',
      shouldHaveCostForCurrentMonth: 'Should have cost for current month',
      hasUndoneLeavingChecklistItems: 'Has undone leaving checklist items',
      isActiveButHasWorkEndInThePast: 'Is active, but has work end in the past',
    },
    type: 'Type',
    types: {
      INTERNAL: 'Internal',
      EXTERNAL: 'External',
    },
    generateLeavingChecklist: 'Generate leaving checklist',
    leavingChecklistDialog: {
      title: 'Generate leaving checklist',
      text: 'Are you sure you want to reset current leaving checklist?',
    },
    responsiblePerson: 'Responsible person',
    markSkillsUpToDateSuccess: 'Skills were successfully marked as up to date',
    requiredFields: 'Required fields are marked by: ',
  },
  collaborator: {
    positions: 'Positions',
    collaborators: 'Collaborators',
    create: 'Create a new collaborator',
    search: 'Search collaborators',
    positionSelect: 'Select positions',
    edit: 'Edit collaborator',
    firstName: 'First name',
    lastName: 'Last name',
    generatedId: 'Id',
    privateEmail: 'Private e-mail',
    workEmail: 'Work e-mail',
    phone: 'Phone',
    birthdate: 'Birthdate',
    permanentResidence: 'Permanent residence / Business address',
    personalIdentificationNumber: 'Personal identification number',
    education: 'Education',
    profileUrlUpdatedAt: 'Last profile url update',
    updatedByAndWhen: 'Updated {date} by {collaborator}',
    needsToBeUpdated: 'Profile needs to be updated, last change is older than 3 months',
    confirmProfileUpToDate: {
      title: 'Has the profile been updated?',
      text: 'Profile will be marked as up to date after confirmation.',
      confirm: 'Mark profile as up to date',
      success: 'Profile marked as up to date',
    },
    position: {
      name: 'Position',
    },
    organization: {
      name: 'Organization',
    },
    legalType: 'Legal type',
    BOZP: 'BOZP',
    medicalCheck: 'Medical check',
    bankAccountNumber: 'Bank account number',
    workStart: 'Work start',
    workEnd: 'Work end',
    jiraId: 'Jira id',
    organizations: 'Organizations',
    lookingForCollaborators: 'Looking for collaborators...',
    noCollaboratorYet: 'No collaboraor yet.',
    saveSuccess: 'Collaborator saved',
    saveSkillsSuccess: 'Collaborator skills saved',
    deleteSuccess: 'Collaborator deleted',
    deleteFailed: 'Collaborator delete failed',
    editCollaboratorSkills: 'Edit collaborator skills',
    removeCollaboratorSkill: 'Remove collaborator skill',
    addCollaboratorSkill: 'Add collaborator skill',
    editCollaborator: 'Edit collaborator',
    deleteCollaborator: 'Delete collaborator',
    collaboratorSkillSlider: {
      min: '5 is the worst',
      max: '1 is the best',
    },
    noSkillFound: 'No skill with value 1 to 5 found for collaborator',
    assets: 'Collaborator assets',
    fullName: 'Full name',
    profileUrl: 'Profile URL',
    activeCollaborators: 'Active collaborators',
    active: 'Active',
    leavingChecklist: 'Leaving Checklist',
    warnings: {
      shouldHaveBozpAndMedicalCheck: 'Should have BOZP and medical check',
      shouldHaveBozp: 'Should have BOZP',
      shouldHaveMedicalCheck: 'Should have medical check',
      shouldHaveBaseWorkingDaysCountForCurrentMonth: 'Should have base working days count for current month',
      shouldHaveCostForCurrentMonth: 'Should have cost for current month',
      hasUndoneLeavingChecklistItems: 'Has undone leaving checklist items',
      isActiveButHasWorkEndInThePast: 'Is active, but has work end in the past',
    },
    type: 'Type',
    types: {
      INTERNAL: 'Internal',
      EXTERNAL: 'External',
    },
    generateLeavingChecklist: 'Generate leaving checklist',
    leavingChecklistDialog: {
      title: 'Generate leaving checklist',
      text: 'Are you sure you want to reset current leaving checklist?',
    },
    responsiblePerson: 'Responsible person',
    markSkillsUpToDateSuccess: 'Skills were successfully marked as up to date',
  },
  collaboratorNotes: {
    editCollaboratorNotes: 'Edit collaborator notes',
    editCollaboratorNote: 'Edit note',
    deleteCollaboratorNote: 'Delete note',
    enterYourNote: 'Enter your note ...',
    deleteSuccess: 'Note deleted',
    saveSuccess: 'Note saved',
    buttons: {
      addNote: 'Add note',
    },
  },
  project: {
    name: 'Project name',
    status: 'Project status',
    abbreviation: 'Project abbr',
    category: 'Category',
    type: 'Type',
    organization: {
      name: 'Organization name',
    },
    projectInfo: 'Project info',
    projectTools: 'Project tools',
    paidActivities: 'Paid activities',
    techFides: 'TechFides',
    client: 'Client',
    billing: 'Billing',
    projectManager: {
      name: 'Project manager',
    },
    salesManager: {
      name: 'Sales manager',
    },
    responsibleManager: 'Responsible manager',
    billingData: 'Billing data (address, VAT number etc.)',
    billingInfo: 'Billing info (notes)',
    billingEmails: 'Billing emails',
    billingParameters: 'Billing parameters',
    reporting: {
      heading: 'Reporting',
      monthly: 'Monthly',
      weekly: 'Weekly',
    },
    plannedHours: 'Planned hours',
    dueInDays: 'Due in days',
    firstReminder: 'First reminder',
    nextReminder: 'Next reminder',
    internal: 'Internal',
    external: 'External',
    inHouse: 'InHouse',
    outsource: 'Outsource',
    active: 'Active',
    search: 'Search projects',
    create: 'Create new project',
    edit: 'Edit project',
    delete: 'Delete project',
    crmLink: 'CRM link',
    technicalLead: 'Technical (dev) lead',
    projectMainGoal: 'Main goal',
    projectMainRisks: 'Main risks',
    analysisPaid: 'Analysis',
    developPaid: 'Develop',
    testingPaid: 'Testing',
    projectManagementPaid: 'PM',
    collaborationType: 'Collaboration type',
    ticketSystem: 'Ticket system',
    gitRepository: 'GIT repository',
    communicationTools: 'Communication tools',
    documentationLink: 'Documentation link',
    otherProjectNotes: 'Notes',
    collaborationTypeItem: {
      TIME_AND_MATERIAL: 'Time & Material',
      FIX_PRICE: 'Fix price',
    },
    noteForThisMonth: 'Note for this month',
    saveSuccess: 'Project saved',
    orders: 'Orders',
    invoices: 'Invoices',
    contacts: 'Contacts',
    deleteProject: 'Delete project',
    deleteSuccess: 'Project deleted',
    areYouSureToDelete: 'Are you sure you want to delete this project?',
    noProjects: 'You either have no projects or insufficient permissions to view projects',
  },
  projectContacts: {
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    position: 'Position',
    notes: 'Notes',
    contactCreated: 'Contact created',
    contactUpdated: 'Contact updated',
    contactDeleted: 'Contact deleted',
  },
  invoice: {
    search: 'Search invoices',
    create: 'Create new invoice',
    edit: 'Edit invoice',
    delete: 'Delete invoice',
    view: 'View invoice',
    taxPointDate: 'Tax point date',
    taxPointDateMonth: 'Tax point date month',
    dateCreated: 'Date created',
    dateOfPayment: 'Due date',
    dueDate: 'Due Date',
    variableSymbol: 'Variable symbol',
    datePaid: 'Date paid',
    priceWithoutVat: 'Price (without VAT)',
    currency: 'Currency',
    vatPercent: 'VAT (%)',
    vatAmount: 'VAT',
    invoiceNumber: 'Invoice number',
    project: {
      name: 'Project',
    },
    confirm: 'Confirm',
    cancel: 'Cancel',
    saveSuccess: 'Invoice saved',
    expectedAmount: 'Expected amount',
    note: 'Note',
    invoiceStatus: 'Status',
    status: {
      PAID: 'Paid',
      PENDING: 'Pending',
      UNPAID: 'Unpaid',
    },
    order: 'Order',
    organization: 'Organization',
    type: 'Type',
    types: {
      STANDARD: 'Standard',
      PROFORMA: 'Proforma',
    },
    proformaInvoices: 'Proforma invoices',
  },
  invoiceReminder: {
    editTitle: 'Process invoice reminder',
    viewInvoice: 'View invoice',
    reminderNumber: 'Invoice reminder number',
    invoice: {
      name: 'Invoice number',
    },
    project: {
      name: 'Project',
    },
    organization: {
      name: 'Organization',
    },
    priceWithoutVat: 'Price (without VAT)',
    priceWithVat: 'Price (with VAT)',
    dateOfPayment: 'Date of payment',
    dueDate: 'Due Date',
    amountToPay: 'Amount to pay',
    daysAfterDateOfPayment: 'Days after date of payment',
    status: 'Status',
  },
  all: 'All',
  noDataForSelectedDate: 'No data for selected date',
  noDataForSelectedFilter: 'No data for selected filter',
  showInChart: 'Show in chart',
  yes: 'yes',
  no: 'no',
  searching: 'Searching...',
  noData: 'No data found',
  clearFilter: 'Clear filter',
  deleteDialog: {
    title: 'Delete',
    text: 'Do you really want to delete',
    delete: 'Delete',
  },
  navigateAwayDialog: {
    title: 'Are you sure you want to leave?',
    text: 'Any unsaved changes will be lost.',
  },
  personalSettings: {
    tables: 'Tables',
    table: {
      itemsPerPage: 'Items per page',
    },
  },
  percent: '%',
  md: 'MD',
  h: 'h',
  mdAvgMo: 'MD avg ({monthsCount}mo) | MD avg ({monthsCount}mo) | MD avg ({monthsCount}mo)',
  hAvgMo: 'h avg ({monthsCount}mo) | h avg ({monthsCount}mo) | h avg ({monthsCount}mo)',
  darkMode: 'Dark Mode',
  month: 'Month',
  monthName:
    'January | February | March | April | May | June | July | August | September | October | November | December',
  year: 'Year',
  updated: 'Updated',
  orders: {
    id: 'ID',
    search: 'Search orders',
    create: 'Create new order',
    edit: 'Edit order',
    delete: 'Delete order',
    view: 'View order',
    validFrom: 'Valid from',
    validTo: 'Valid to',
    requestedCapacity: 'Requested capacity in MD',
    confirmType: 'Confirm type',
    projectName: 'Project',
    state: 'State',
    organizationName: 'Organization',
    stateName: 'State',
    validDateIn: 'Valid in',
    validDateError: "'Valid to' must be greater or equal than 'Valid from'",
    orderExistsError: 'Order with this number already exists',
    confirm: 'Confirm',
    cancel: 'Cancel',
    saveSuccess: 'Order saved',
    note: 'Note',
    states: 'States',
    files: 'Files',
    fileNames: 'Files',
    fileUploadError: 'Can not save files',
    maxFileErrorMessage: 'You can not add more than 10 files',
    maxFileSizeErrorMessage: 'Max file size is 30MB',
    projects: 'Projects',
    dropText: 'Drop files here',
  },
  companyWorkPerformance: {
    workedTimeExternalProjects: 'Worked time - external projects',
    workedTimeInternalProjects: 'Worked time - internal projects',
    occupancy: 'Occupancy',
    realOccupancy: 'Real Occupancy',
    occupancyThisMonth: 'Occupancy 1st day this month',
    occupancyNextMonth: 'Occupancy 1st day next month',
    paidInvoicesSum: 'Billed without VAT',
    averagePrice: 'Average price',
    averagePriceOverLast3Months:
      'Average price over last {monthsCount} months | Average price over last {monthsCount} months | Average price over last {monthsCount} months',
    help: {
      realOccupancy:
        'Reálné vytížení spočítané jako poměr mezi odpracovanýma hodinama mezi interníma a externíma projektama, dle logů v jiře',
      paidInvoicesSum:
        'Suma fakturovaných částek, dle faktur zaevidovanch v ERP. Nebere se v potaz jejich proplacenost. Pouze INHOUSE projekty, tedy bez OUTSORCE',
      averagePrice: 'Průměrná cena za měsíc, dle fakturovaných částek a odpracovaného času na externích projektech',
    },
  },
  state: {
    name: 'State',
  },
  salesRoadmap: {
    rangePickerLabel: 'Select months',
  },
  homePage: {
    help: {
      badges: {
        menuItemsBadgesMeaning: 'Menu items badges meaning:',
        invoices: {
          name: 'Invoices:',
          meaning: 'Unpaid invoices overdue count',
        },
        orders: {
          name: 'Orders:',
          meaning: 'Invalid orders with unpaid invoices count',
        },
        projectPlan: {
          name: 'Employee project plan:',
          meaning: 'Occupancy (percent of used employee capacity) for the next month',
        },
        projectPlanV2: {
          name: 'Employee project plan:',
          meaning: 'Occupancy (percent of used employee capacity) for the next month',
        },
        projectCalculation: {
          name: 'Project calculation:',
          meaning: 'Count of projects in last month with costs and unsent report',
        },
        bankTransactionsV2: {
          name: 'Bank transactions:',
          meaning: 'Uncategorized outgoing payments count',
        },
        worklogAnalysis: {
          name: 'Worklog analysis:',
          meaning: 'New worklog issues count',
        },
        employees: {
          name: 'Employees:',
          meaning: 'Employee issues count',
        },
      },
    },
  },
  ticketTimeCorrection: {
    ticketTimeCorrection: 'Ticket time correction',
    date: 'Date',
    comment: 'Comment',
    timeCorrectionInHours: 'Time correction in hours',
    deleteThisCorrection: 'Delete this correction',
    dateIsRequired: 'Date is required',
    timeCorrectionIsRequired: 'Time correction is required',
    createNewTicketTimeCorrection: 'Create new ticket time correction',
  },
  employeeSalesInfo: {
    mdCzkThisMonth: 'CZK/MD this month',
    hCzkThisMonth: 'CZK/h this month',
    mdCzkLastThreeMonths: 'CZK/MD last 3 months',
    hCzkLastThreeMonths: 'CZK/h last 3 months',
    costs: 'Costs',
    salesNotes: 'Sales Notes',
    otherSpecialistsAveragesWithSamePosition: 'Other specialists averages with same position',
    employeePriceWarning:
      'This price is under or equal (avg position price +20%). You won’t be probably able to replace this specialist for the current price.',
    employeePriceOk: 'This price is above (avg position price +20%).',
  },
  employeeCapacityReport: {
    showEmployees: 'Show employees',
    occupancy: 'Occupancy',
    filter: {
      showingItemOfItems: 'Showing {visibleItemsCount} of {itemsCount}, filter applied:',
      availableCapacity: 'available capacity >= {capacity}MD',
      skillValue: 'skill value >= {skill}',
      department: 'department = {department}',
    },
  },
  agGridOptionsSidebar: {
    expandHideRows: 'Epand / hide rows',
    changePagination: 'Number of rows per page',
    savePersonalSettings: 'Save table settings',
    resetColumns: 'Reset columns',
    columnsSettings: 'Columns settings',
    export: 'Export',
    downloadCsv: 'Download CSV',
    downloadExcel: 'Download EXCEL',
    fixedCols: 'Set columns automatically',
  },
  majorSkills: 'Major skills',
  juniorSkills: 'Junior skills',
  mediorSkills: 'Medior skills',
  seniorSkills: 'Senior skills',
  profileLink: 'Profile link',
  info: 'Info',
  notes: 'Notes',
  id: 'ID',
  skills: 'Skills',
  note: 'Note',
  corrections: 'Corrections',
  fileInput: {
    deleteAllFiles: 'Do you really want to delete all files',
  },
  cEditableTable: {
    create: 'Create item',
    disabled: 'Inputs are invalid',
  },
  cEditableRowItem: {
    deleteItem: 'Delete item',
  },
  checked: 'Checked',
  next: 'next',
  prev: 'prev',
  accessDenied: 'Access denied',
  department: 'Department',
  currentCompany: 'Current company',
  tooltips: {
    skillHeatmap: {
      skill: 'Skill:',
      value: 'Value:',
    },
  },
  employeeNotes: {
    editEmployeeNotes: 'Edit employee notes',
  },
  personalSettingsPage: {
    personalSettings: 'Personal Settings',
    userProfile: 'User Profile',
    socketEvents: 'Socket events',
    idToken: 'ID token',
  },
  collaboratorProfilesPage: {
    selectFirst: 'Please select a collaborator first',
    save: 'Save',
    lastEdit: 'Last edit',
    by: 'by',
    baseInfo: 'Base info',
    experience: 'number of years of experience',
    skills: 'Skills',
    other: 'Other',
    projects: 'Projects',
    strengthsWeaknesses: 'Strengths and weaknesses',
    otherTechnologies: 'Other technologies',
    workedMD: 'worked MD:',
    monthsOnProject: 'months on project:',
    certifications: 'Certifications',
    profileUrl: 'Profile URL',
    designAndArchitecture: {
      softwareArchitecture: 'Design the architecture of software systems',
      databaseArchitecture: 'Design the architecture of databases',
      technologySelection: 'Select appropriate technologies for projects',
      technicalLeadership: 'Lead technical teams to success',
    },
    development: {
      frontend: 'Work on the frontend of applications',
      backend: 'Work on the backend of applications',
      database: 'Manage and maintain databases',
      api: 'Develop and maintain APIs',
    },
    testingAndQA: {
      unitTesting: 'Write and run unit tests',
      e2eTesting: 'Perform end-to-end testing',
      codeReview: 'Review and approve code changes',
      bugFixing: 'Fix bugs and issues in the code',
    },
    maintenance: {
      refactoring: 'Improve existing code through refactoring',
      support: 'Provide support for the system',
    },
    collaborationAndCommunication: {
      customerCommunication: 'Communicate with customers and clients',
      teamCollaboration: 'Collaborate with the team',
      documentation: 'Create and update documentation',
    },
    projectManagement: {
      estimationAndPlanning: 'Estimate time and resources for projects',
      taskManagement: 'Manage and assign tasks',
      monitoringAndReporting: 'Monitor progress and generate reports',
    },
  },
};
